body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  height: 100vh;
  overflow-x: hidden; /* Change from overflow: hidden to overflow-x: hidden */
  width: 100%;
  max-width: 100vw;
}

.modalContent {
  line-height: 1.5;
  padding: 20px;
  overflow-y: auto
}

.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000080
}

.modal-content {
  position: relative;
  width: 80vw;
  max-width: 800px;
  max-height: 90vh;
  height: 80vh;
  overflow: auto;
  padding: 20px;
  background-color: #fffc;
  border-radius: 10px;
  box-shadow: 0 4px 20px #00000026
}

.modal-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px
}

.error-container {
  background-color: #ffe6e6;
  border: 1px solid #ff4d4d;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start
}

.error-container p {
  margin: 0
}

.error-container_modal {
  background-color: #ffe6e6;
  border: 1px solid #ff4d4d;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start
}

.error-icon {
  background-color: #ff4d4d;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 700
}

.error-text {
  color: #ff4d4d;
  font-weight: 700;
  flex: 1
}

.success-container {
  background-color: #d9f2d9;
  border: 1px solid #2e8b57;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start
}

.success-container_modal {
  background-color: #d9f2d9;
  border: 1px solid #2e8b57;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start
}

.success-container p {
  margin: 0
}

.success-icon {
  background-color: #2e8b57;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 700
}

.success-text {
  color: #2e8b57;
  font-weight: 700;
  flex: 1
}

.language-flags {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
  background-color: #ffffffb3;
  border-radius: 8px;
  padding: 5px 10px;
  box-shadow: 0 0 5px #0000004d;
  display: flex;
  align-items: center;
  gap: 5px
}

.language-flags img {
  cursor: pointer;
  height: 20px;
  width: auto;
  transition: transform .2s
}

.language-flags img:hover {
  transform: scale(2.1)
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center
}

.double_auth_button {
  background-color: rgb(51, 154, 216) !important;
  color: #fff !important;
  padding: 8px 16px !important;
  border-radius: 8px !important;
  width: 240px !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) !important;
  text-transform: none !important;
}

.phoneInputContainer {
  text-align: center;
  margin-bottom: 20px;
}

.phoneInput {
  max-width: 320px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
}

.phone_input_class input {
  padding: 10px;
}

.session-fade-out {
  opacity: 0 !important;
  max-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
  transition: opacity 0.3s ease, max-height 0.3s ease, padding 0.3s ease, margin 0.3s ease;
}

.modal_description {
  font-family: 'Poppins', sans-serif !important;
  color: rgb(99, 115, 129) !important;
  padding-bottom: 20px;
}

.modal_title, .notifications_title {
  font-family: 'Poppins', sans-serif !important;
  color: #339AD8 !important;
  padding-bottom: 30px;
}

.table_header{
  font-family: 'Poppins', sans-serif !important;
  color: rgb(99, 115, 129) !important;
}

.modal_list_with_border {
  color: #637381;
  font-size: 0.875rem;
  font-family: 'Poppins', sans-serif;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f0f0f0;
  width: 100%;
}

.notifications_list_with_border {
  color: #637381;
  font-size: 0.875rem;
  font-family: 'Poppins', sans-serif;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f0f0f0;
  width: 100%;
  cursor: pointer;
}

.table_body {
  font-family: 'Poppins', sans-serif !important;
  color: rgb(99, 115, 129) !important;
  display: flex; /* Flexbox layout */
  align-items: center; /* Vertical alignment */
  justify-content: flex-start; /* Horizontal alignment, if needed */
}


.table_bottom{
  font-family: 'Poppins', sans-serif !important;
  color: rgb(99, 115, 129) !important;
}

/* Default styles for icons */
.icon-edit svg,
.icon-delete svg {
  color: #AAAAAA; /* Default color for both icons */
}

/* Hover styles for the edit icon */
.icon-edit:hover svg {
  color: #daa520; /* Color change to yellow on hover for the edit icon */
}

/* Hover styles for the delete icon */
.icon-delete:hover svg {
  color: red; /* Color change to red on hover for the delete icon */
}

@media (min-width: 1501px) {
  .container {
    display: flex;
    height: 200vh;
    width: 100%
  }
}

.app-wrapper {
  width: 100%;
  width: 100%;
  overflow-x: hidden;
}

/* Additions for resizable modal functionality */
.MuiDialog-paper {
  resize: both;
  overflow: auto;
  max-width: none; /* Allows the dialog to expand beyond default limits */
  max-height: none;
  width: 200vw; /* Adjust this value to set how wide the dialog can be */
}