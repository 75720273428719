/* Common Styles */

.overlayImage {
    padding: 50px;
      }

.passwordIcon,
.language-flags img,
.forgotPassword,
#creaAccountLink {
    cursor: pointer;
}

.unauthenticated_button {
    background-color: rgb(51, 154, 216) !important;
    color: #fff !important;
    padding: 8px 16px !important;
    border-radius: 8px !important;
    width: 100% !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) !important;
    text-transform: none !important;
}

.unauthenticated_button:hover {
    background-color: #333333;
}

.kyc_logout_button {
    background-color: #AAAAAA !important;
    color: #fff !important;
    padding: 8px 16px !important;
    border-radius: 1rem !important;
    width: 240px;
    font-weight: 700 !important;
    font-size: 15px !important;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) !important;
    text-transform: none !important;
    left: calc(50% - 120px);
    margin-top: 50px;
}
.kyc_logout_button:hover {
    background-color: #&&&&&&;
}

.terms {
    margin-top: 20px;
    text-align: center;
}

.input_login::placeholder {
    color: #CCCCCC !important;
    font-family: 'Poppins', sans-serif !important;
}

.otpInputs .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: 2px solid #4a90e2;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.processingButton {
    background-color: #AAAAAA !important;
    color: #FFFFFF !important;
}

.modalContent {
    line-height: 1.5;
    padding: 20px;
}

.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
}

.modal-content {
    position: relative;
    min-width: 300px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.modal-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
}

.modal-description {
    font-size: 18px;
}

.language-flags {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1000;
    background-color: #ffffffb3;
    border-radius: 8px;
    padding: 5px 10px;
    box-shadow: 0 0 5px #0000004d;
    display: flex;
    align-items: center;
    gap: 5px;
}

.language-flags img {
    height: 20px;
    width: auto;
    transition: transform .2s;
}

.language-flags img:hover {
    transform: scale(2.1);
}

.login_logo_desktop{
    width: 100px;
    height: 100px;
}
.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.kyc_container {
    width: 100%;
    height: 100%;
}

.kcy_main_div {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.kcy_inside_div {
    position: fixed;
    /* Changed from absolute to fixed */
    margin-top: 60px;
    width: 100%;
    height: 100%;
    z-index: 10;
    overflow-y: auto;
}

.kyc_process_bg {
    width: 900px;
    height: calc(100% - 20px);
    position: fixed;
    top: 20px;
    left: calc (50% - 450px);
    background-color: rgba(240, 240, 240, 0.95);
    z-index: 1;
    border-radius: 10px;
}

/* Media Queries */
@media (max-width: 1500px) {
    .forgotPassword {
        width: 100%;
        display: block;
        padding-top: 20px;
        padding-bottom: 10px;
        color: #000;
        font-weight: 600;
    }

    .login_logo_mobile {
        width: 45px;
        height: 75px;
    }

    .container {
        display: flex;
        height: 100vh;
        justify-content: center;
        align-items: center;
        padding: 15px;
    }

    .unauthenticated_left_container,
    .logged_in_left_container {
        display: none;
    }

    .unauthenticated_right_container>*+* {
        padding-top: 2px;
    }

    .unauthenticated_right_container,
    .logged_in_right_container {
        background-color: #ffffff;
        padding: 40px;
        border-radius: 8px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        max-width: 500px;
        width: 100%;
        text-align: center;
    }

    button {
        display: block;
    }

    .otpInputs .MuiOutlinedInput-root {
        border: 1px solid #ffffff !important;
        border-radius: 5px;
        width: 40px;
        margin: 2px;
        box-shadow: 3px 3px 6px #f0f0f0;
    }
}

@media (min-width: 1501px) {
    .forgotPassword {
        text-align: right;
        width: 100%;
        display: block;
        margin: 10px 0;
        color: #000;
        font-weight: 600;
    }

    .login_logo_mobile {
        display: none;
    }

    .container {
        display: flex;
        height: 100vh;
        width: 100%;
    }

    .unauthenticated_left_container,
    .logged_in_left_container {
        flex: 3;
        background-color: #6096ba99;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        padding:50px;
    }

    .unauthenticated_right_container,
    .logged_in_right_container {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 70px;
        gap: 20px;
        max-width: 340px !important;
        width: 100%;
    }

    .otpInputs .MuiOutlinedInput-root {
        border: 1px solid #ffffff !important;
        border-radius: 5px;
        width: 42px;
        margin: 7px;
        box-shadow: 3px 3px 6px #f0f0f0;
    }
}